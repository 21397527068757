<template>
  <fragment>
    <label :disabled="disabled">R$</label>
    <input
      inputmode="numeric"
      :disabled="disabled"
      :size="size"
      :name="name"
      class="form-input"
      v-model="computedValue"
      v-on:blur="onBlur"
      ref="input"
    />
  </fragment>
</template>

<script>
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'

export default {
  props: {
    value: { type: String, default: '0', twoWay: true },
    name: { type: String },
    size: { type: String },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    computedValue: {
      get() {
        return this.format(this.value)
      },
      set(_) {},
    },
  },
  mounted: function () {
    this.onBlur({ target: { value: this.value } })
  },
  methods: {
    format: function (value) {
      const newValue = format(BigNumber(this.removeText(value)), 'numeric', {
        minDecimals: 2,
      })
      return newValue === 'NaN' ? 0 : newValue
    },
    onBlur: function ({ target: { value } }) {
      this.$refs.input.value = this.format(value)
      this.$emit('input', this.$refs.input.value)
    },
    removeText: function (value) {
      return value.match(/,[0-9]*$/i)
        ? value
            .replace('.', '')
            .replace(/[^0-9,\.]/g, '')
            .replace(',', '.')
        : value
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  label {
    z-index: 1;
    position: absolute;
    margin: 9px 2px;

    &[disabled='disabled'] {
      color: rgb(159, 159, 159);
    }
  }

  input {
    width: 90%;
    padding-left: 25px;
  }
}
</style>
