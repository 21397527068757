export default function config() {
  Array.from(
    document.querySelectorAll(
      '.form-switch input[type="checkbox"][checkbox-for]'
    )
  ).forEach((element) => {
    disableRelative({ target: element })
    element.addEventListener('change', disableRelative)
  })
}

function disableRelative({ target }) {
  const inputName = target.getAttribute('checkbox-for')
  const invert = target.getAttribute('checkbox-invert')
  const input = document.querySelector(`[name="${inputName}"]`)

  if (input) input.disabled = invert ? !target.checked : target.checked
}
