export function buildName(name) {
  const nameLength = 24
  if (name.length > nameLength)
    return `${name.substring(0, nameLength / 2)}...${name.substring(
      name.length - nameLength / 2,
      name.length
    )}`

  return name
}
