<template>
  <div class="container kit-itens">
    <div class="columns">
      <div class="column col-12">
        <div class="columns">
          <div
            v-for="(kitProduct, index) in kit.kit_products"
            v-bind="kit"
            :key="index"
            class="column col-12 kit-product"
          >
            <kit-item :kitProduct="kitProduct"></kit-item>
          </div>
        </div>
      </div>
      <div class="column col-12">
        <button
          class="btn btn-primary float-right"
          type="button"
          v-on:click="addProduct"
        >Incluir produto</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as dealerService from '../kit/service'
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'
import KitItem from './KitItem.vue'

export default {
  data() {
    return {}
  },
  props: {
    kit: { type: Object, default: {}, twoWay: true },
  },
  methods: {
    addProduct() {
      this.kit = {
        ...this.kit,
        kit_products: [
          ...this.kit.kit_products,
          {
            id: null,
            product_id: '',
            product: {},
            quantity: '0',
            price: '0',
            _destroy: false,
          },
        ],
      }
    },
  },
  components: { KitItem },
}
</script>

<style lang="scss">
.kit-itens {
  margin-top: 50px;
}
</style>
