import { showToast } from './toast'

document.addEventListener('ajax:success', onSuccess)
document.addEventListener('ajax:error', onError)

function onSuccess(event) {
  if (event.srcElement && event.srcElement.querySelector('.modal')) {
    const closeButton = event.srcElement.querySelector(
      'btn[action="close"], a[action="close"]'
    )

    closeButton && closeButton.click()
  }

  if (event.srcElement && event.srcElement.tagName.toUpperCase() === 'FORM') {
    if (location.href.includes('/products')) location.reload()
  }

  showToast('success', 'Sucesso')
}

function onError(event) {
  if (event.srcElement.tagName.toUpperCase() === 'FORM') {
    const errors = event.detail[0]
    errors &&
      Object.keys(errors).forEach((fieldName) => {
        const element = event.srcElement.querySelector(`[name="${fieldName}"]`)
        element && element.classList.add('is-error')
      })
  }

  showToast('error', 'Algo deu errado, verifique seu formulário')
}
