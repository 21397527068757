<template>
  <fragment>
    <div class="form-group">
      <label for="order_Tipo" class="form-label">Tipo</label>
      <select
        name="order[kind]"
        id="order_kind"
        class="form-select"
        v-model="order.kind"
      >
        <option value></option>
        <option value="direct_sale">Venda direta</option>
        <option value="consigned_sale">Venda consignada</option>
      </select>
    </div>

    <div class="container order-itens">
      <div class="columns">
        <div class="column col-12">
          <div class="columns">
            <div
              v-for="(orderProduct, index) in order.order_products"
              v-bind="order"
              :key="index"
              class="column col-12 order-product"
            >
              <order-item
                :orderProduct="orderProduct"
                :kind="order.kind"
              ></order-item>
            </div>
          </div>
        </div>
        <div class="column col-12">
          <button class="btn btn-primary float-right" v-on:click="addProduct">
            Incluir produto
          </button>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import * as dealerService from '../order/service'
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'
import OrderItem from './OrderItem.vue'

export default {
  data() {
    return {
      order: {
        kind: 'direct_sale',
      },
    }
  },
  props: {
    initialData: { type: String, default: '{}' },
  },
  mounted: async function () {
    const initialData = this.initialData
    const order =
      typeof initialData === 'string' && initialData !== ''
        ? this.parseOrder(initialData)
        : initialData
    this.order = { ...this.order, ...order }
  },
  methods: {
    update(itemId, key, value) {
      this.$emit('input', { ...this.order, [key]: value })
    },
    parseOrder(orderString) {
      const order = JSON.parse(orderString)
      order.order_products = order.order_products.map((orderProduct) => {
        const product = orderProduct.product || {}
        orderProduct.id = orderProduct.id || ''
        orderProduct.product = { ...product, id: product.id || '' }
        return orderProduct
      })

      return order
    },
    addProduct() {
      this.order = {
        ...this.order,
        order_products: [
          ...this.order.order_products,
          {
            id: null,
            product_id: '',
            product: {},
            quantity: '0',
            price: '0',
            _destroy: false,
          },
        ],
      }
    },
  },
  filters: {
    integer: (value) => format(BigNumber(value), 'integer'),
    currency: (value) => format(BigNumber(value), 'currency'),
  },
  components: { OrderItem },
}
</script>

<style lang="scss">
.order-itens {
  margin-top: 50px;
}
</style>
