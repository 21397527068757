import * as product from './product-service'
import { buildName } from './file-utils'
import { DirectUpload } from '@rails/activestorage'

window.loadDefaultAttributesForEdit = function(id) {
  const form = document.querySelector('form[name="edit-form"]')

  const elementsToRemove = form.querySelectorAll(
    '.columns.images .image-element'
  )

  if (elementsToRemove) {
    Array.from(elementsToRemove).forEach(element => {
      element.parentNode.removeChild(element)
    })
  }

  product.getProduct(id).then(async response => {
    const data = await response.json()

    fillFormModal(data, form)
    renderImages(data.images, form, id)
  })

  form.setAttribute('action', `/products/${id}`)

  const fileInput = form.querySelector('input[type="file"]')
  fileInput.closest('div').onclick = () => {
    fileInput.click()
  }

  fileInput.onchange = ({ target }) => {
    const fileList = document.createElement('div')
    fileList.classList.add('column')
    fileList.classList.add('col-12')
    fileList.classList.add('mt-3')
    fileList.classList.add('mr-2')
    Array.from(target.files).forEach(({ name }) => {
      const container = document.createElement('div')
      container.classList.add('col-12')
      container.append(buildName(name))
      fileList.append(container)
    })

    const container = form.querySelector('.files-to-upload')
    container.innerHTML = ''
    container.append(fileList)
  }
}

function renderImages(images, form, productId) {
  const imagesContainer = form.querySelector('.columns.images')

  images.forEach(image => {
    const container = document.createElement('div')
    container.classList.add('column')
    container.classList.add('col-5')
    container.classList.add('mt-3')
    container.classList.add('mr-2')
    container.classList.add('image-element')
    const img = document.createElement('img')
    img.src = image.src
    img.alt = 'Foto de uma joia'

    const deleteLabel = document.createElement('label')
    deleteLabel.innerText = 'x'
    deleteLabel.classList.add('clicable')
    deleteLabel.classList.add('delete-label')
    deleteLabel.classList.add('float-right')
    deleteLabel.classList.add('p-absolute')
    deleteLabel.addEventListener('click', () => {
      product.deleteImage(productId, image.id).then(() => {
        imagesContainer.removeChild(container)
      })
    })

    container.append(deleteLabel)
    container.append(img)
    imagesContainer.appendChild(container)
  })
}

function fillFormModal(data, form) {
  const dataKeys = Object.keys(data)
  dataKeys.forEach(key => {
    const element = form.querySelector(`[name="${key}"]`)
    if (element && data[key]) {
      data[key] instanceof Object
        ? fillObjectField(form, element, data[key], key)
        : fillField(element, data[key].toString())
      element.dispatchEvent(new Event('blur'))
    }
  })
}

function fillObjectField(form, element, data, key) {
  fillField(element, data.name)
  const idField = form.querySelector(`[name="${key}_id"]`)
  if (idField) fillField(idField, data.id)
}

function fillField(field, value) {
  if (field.type.toLowerCase() === 'file') return
  if (field.type.toLowerCase() === 'checkbox') {
    if (field) field.checked = value
  } else {
    if (field) field.value = value
  }
}
