<template>
  <input
    inputmode="numeric"
    type="text"
    :size="this.size"
    :name="name"
    class="text-right"
    v-bind:value.sync="computedValue"
    v-on:blur="formatField"
    v-on:input="updateValue($event.target.value)"
    ref="input"
  />
</template>

<script>
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'

export default {
  props: {
    value: { type: String, default: '0', twoWay: true },
    name: { type: String },
    size: { type: String }
  },
  computed: {
    computedValue: {
      get() {
        return this.format(this.value)
      },
      set(value) {
        this.$emit(
          'input',
          this.format(BigNumber(this.removeText(value)), 'numeric')
        )
      }
    }
  },
  mounted: function() {
    this.formatField({ target: { value: this.value } })
    this.updateValue(this.value)
  },
  methods: {
    format: function(value) {
      const newValue = format(BigNumber(this.removeText(value)), 'integer')
      return newValue === 'NaN' ? 0 : newValue
    },
    formatField: function({ target: { value } }) {
      this.$refs.input.value = this.format(value)
      this.$emit('input', this.$refs.input.value)
    },
    removeText: function(value) {
      return value.replace(/[^0-9,\.]/g, '').replace(',', '.')
    },
    updateValue: function(value) {
      this.$emit('input', format(BigNumber(this.removeText(value)), 'numeric'))
    }
  }
}
</script>
