import { request } from '../request'

export function find(id) {
  return request(`/kits/${id}.json`)
}

export function build(dealerId) {
  return request(`/dealers/${dealerId}/kits/new.json`)
}

export function update(id, data) {
  return request(`/kits/${id}.json`, {
    method: 'put',
    body: JSON.stringify({ kit: { ...data } })
  })
}
