export function start() {
  document.addEventListener('turbolinks:load', initialize)
}

import '@fortawesome/fontawesome-free/js/all'
import './new-modal'
import './edit-modal'
import './submit-print'
import configureModals from './modal'
import configureMasks from './masks'
import configureFactorCalculator from './factor-calculator'
import configureProductCRUD from './product'
import configureAutoblur from './autoblur'
import configureCheckboxFor from './checkbox-for'
import configureMessages from './messages'
import configurePopper from './popper'
import './ajax-events'
import './toast'
import './sidenav'
import './products'
import configureTooltip from './tooltip'
import configureChannels from './channels'

// import tippy from 'tippy.js';
// window.tippy = tippy

function initialize(e) {
  configureMasks()
  configureFactorCalculator()
  configureAutoblur()
  configureCheckboxFor()
  configureMessages()
  configureModals()
  configureProductCRUD()
  configureTooltip()
  configureChannels()
  configurePopper()
}
