<template>
  <fragment v-if="kit">
    <div class="columns">
      <div class="column col-12">
        <kit-itens :kit="kit"></kit-itens>
      </div>
    </div>

    <hr />

    <div class="columns">
      <kit-totals :kit="kit"></kit-totals>
    </div>
  </fragment>
</template>

<script>
import KitItens from './KitItens'
import KitFinalization from './KitFinalization'
import { numeric } from '../numeric'

export default {
  data() {
    return {
      kit: undefined,
    }
  },
  props: {
    initialData: { type: String, default: '' },
  },
  mounted: async function () {
    const initialData = this.initialData
    const kit =
      typeof initialData === 'string' && initialData !== ''
        ? this.parseKit(initialData)
        : initialData
    this.kit = { ...this.kit, ...kit }
  },
  methods: {
    parseKit(kitString) {
      const kit = JSON.parse(kitString)
      kit.kit_products = kit.kit_products.map((kitProduct) => {
        const product = kitProduct.product || {}
        kitProduct.id = kitProduct.id || ''
        kitProduct.product = { ...product, id: product.id || '' }
        return kitProduct
      })

      return kit
    },
    filterProducts(kit) {
      return kit.kit_products.filter(
        (kitProduct) =>
          !kitProduct._destroy &&
          ![kitProduct.quantity, kitProduct.price].includes('')
      )
    },
    calculateTotal(kit, field) {
      return String(
        this.filterProducts(kit).reduce((sum, kitProduct) => {
          return sum + numeric(kitProduct[field]) * numeric(kitProduct.price)
        }, 0)
      )
    },
  },
  watch: {
    kit: {
      handler(kit) {
        if (!kit) return
        this.kit.total = this.calculateTotal(kit, 'quantity')
        this.kit.sold = this.calculateTotal(kit, 'sold')
      },
      deep: true,
    },
  },
  components: {
    KitItens,
    KitFinalization,
  },
}
</script>
