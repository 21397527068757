function openNav() {
  document.getElementById('sidenav').style.width = '250px'
}

function closeNav() {
  document.getElementById('sidenav').style.width = '0'
}

window.openNav = openNav
window.closeNav = closeNav
