import { buildName } from './file-utils'
import * as product from './product-service'

window.loadDefaultAttributesForNew = function () {
  const form = document.querySelector('form[name="new-form"]')

  product.newProduct().then(async (response) => {
    const data = await response.json()

    fillNewFormModal(data)
  })

  const fileInput = form.querySelector('input[type="file"]')
  fileInput.closest('div').onclick = () => {
    fileInput.click()
  }

  fileInput.onchange = ({ target }) => {
    const fileList = document.createElement('div')
    fileList.classList.add('column')
    fileList.classList.add('col-12')
    fileList.classList.add('mt-3')
    fileList.classList.add('mr-2')
    Array.from(target.files).forEach(({ name }) => {
      const container = document.createElement('div')
      container.classList.add('col-12')
      container.append(buildName(name))
      fileList.append(container)
    })

    const container = form.querySelector('.files-to-upload')
    container.innerHTML = ''
    container.append(fileList)
  }
}

function fillNewFormModal(data) {
  const dataKeys = Object.keys(data)
  const form = document.querySelector('form[name="edit-form"]')
  dataKeys.forEach((key) => {
    const element = form.querySelector(`[name="${key}"]`)
    if (element && data[key]) {
      data[key] instanceof Object
        ? fillObjectField(form, element, data[key], key)
        : fillField(element, data[key].toString())
      element.dispatchEvent(new Event('blur'))
    }
  })
}

function fillObjectField(form, element, data, key) {
  fillField(element, data.name)
  const idField = form.querySelector(`[name="${key}_id"]`)
  if (idField) fillField(idField, data.id)
}

function fillField(field, value) {
  if (field.type.toLowerCase() === 'file') return
  if (field.type.toLowerCase() === 'checkbox') {
    if (field) field.checked = value
  } else {
    if (field) field.value = value
  }
}
