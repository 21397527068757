import tippy from 'tippy.js';
import { request } from './request'

export default function configure() {
  Array.from(document.querySelectorAll('[data-template-popper]')).forEach(addWatcher)
}

function addWatcher(element) {
  const templateSelector = element.attributes['data-template-popper'].value
  const templateElement = document.querySelector(templateSelector)

  if(!templateElement) return

  tippy(element, {
    content: templateElement.innerHTML,
    allowHTML: true,
    trigger: 'click',
    interactive: true,

    onShow: tippy => {
      const form = tippy.popper.querySelector('form')

      if(tippy.popper.getAttribute('popper-watching')) return
      tippy.popper.setAttribute('popper-watching', '')

      if(form.attributes['data-local'] && form.attributes['data-local'].value === 'true') return
      form.addEventListener('submit', e => {
        e.preventDefault()
        e.stopPropagation()

        const formData = new FormData(e.target)
        const action = e.target.action
        const method = e.target.attributes.method.value
        const productId = formData.get('product_id')
        const id = formData.get('id')
        const quantity = formData.get('quantity')

        request(action, {
          method,
          body: JSON.stringify({
            id,
            product_id: productId,
            quantity
          })
        })

        tippy.hide()
      })
    }
  });
}
