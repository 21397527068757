export function variableCommission(total, sold) {
  const maximumComission = sold / 2
  const commission = ((sold * 100) / total / 100) * sold

  return commission > maximumComission ? maximumComission : commission
}

export function fixedCommission(sold, fee) {
  return sold > 0 ? (sold * fee) / 100 : 0
}
