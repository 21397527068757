export function showToast(type, toastMessage) {
  const toast = document.querySelector('.toast')

  toast.querySelector('span').innerText = toastMessage

  toast.classList.add(`toast-${type}`)
  toast.classList.add('toast-visible')
  setTimeout(() => {
    hideToast()
  }, 5000)
}

export function hideToast() {
  const toast = document.querySelector('.toast')

  toast.classList.remove('toast-primary')
  toast.classList.remove('toast-success')
  toast.classList.remove('toast-warning')
  toast.classList.remove('toast-error')
  toast.classList.remove('toast-visible')
  toast.classList.add('toast-hidden')
}

window.hideToast = hideToast
