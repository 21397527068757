import { deleteProduct } from './product-service'

window.inativeSelectedProducts = async function inativeSelectedProducts() {
  if (!confirm('Você tem certeza que desa inativar estes produtos?')) return

  Array.from(document.querySelectorAll('[name^="inactive_product_"]')).forEach(
    (input) => {
      const id = input.getAttribute('data-id')
      if (input.checked) deleteProduct(id)
    }
  )
}
