<template>
  <fragment>
    <span v-if="this.errors" class="label label-primary">{{errors}}</span>
    <div class="columns" v-if="this.status !== 'closed'">
      <div class="column col-12">
        <button
          v-on:click="startPayment"
          :disabled="partialPaymentConfirmDisabled"
          type="button"
          class="btn btn-primary"
        >Acerto {{kit.partial ? 'parcial' : ''}}</button>
        <button
          v-on:click="confirmPaymentReceived"
          :disabled="paymentConfirmDisabled"
          type="button"
          class="btn btn-error"
        >Confirmar pagamento recebido {{kit.paymentReceived ? '✓' : ''}}</button>
        <button
          v-on:click="confirmReturnReceived"
          :disabled="returnConfirmDisabled"
          type="button"
          class="btn btn-error"
        >Confirmar devolução recebida {{kit.returnReceived ? '✓' : ''}}</button>
        <button
          v-on:click="endCicle"
          :disabled="endCicleDisabled"
          type="button"
          class="btn btn-error"
          data-confirm="Tem cerza que deseja finalizar o ciclo?"
        >Finalizar ciclo</button>
      </div>
    </div>
  </fragment>
</template>

<script>
import * as kitService from '../kit/service'

export default {
  data() {
    return {
      kit: {},
      errors: undefined,
    }
  },
  props: {
    kitId: { type: String },
    paymentReceived: { type: String, default: 'false' },
    returnReceived: { type: String, default: 'false' },
    partial: { type: String, default: 'true' },
    status: { type: String, default: '' },
  },
  mounted() {
    this.kit = {
      paymentReceived: this.paymentReceived === 'true',
      returnReceived: this.returnReceived === 'true',
      partial: this.partial === 'true',
      status: this.status,
    }
  },
  methods: {
    startPayment() {
      kitService
        .update(this.kitId, {
          status: 'waiting_for_payment',
          payment_received: false,
        })
        .then(async (response) => {
          response.ok
            ? location.reload()
            : (this.errors = await response.text())
        })
    },
    confirmPaymentReceived() {
      kitService
        .update(this.kitId, { payment_received: true })
        .then(async (response) => {
          response.ok
            ? location.reload()
            : (this.errors = await response.text())
        })
    },
    confirmReturnReceived() {
      kitService
        .update(this.kitId, { return_received: true })
        .then(async (response) => {
          response.ok
            ? location.reload()
            : (this.errors = await response.text())
        })
    },
    endCicle() {
      kitService
        .update(this.kitId, { status: 'closed' })
        .then(async (response) => {
          response.ok
            ? location.reload()
            : (this.errors = await response.text())
        })
    },
  },
  computed: {
    endCicleDisabled() {
      return (
        !this.kit.paymentReceived ||
        !this.kit.returnReceived ||
        !['waiting_for_payment', 'waiting_for_return'].includes(this.kit.status)
      )
    },
    returnConfirmDisabled() {
      return this.kit.returnReceived || this.kit.status !== 'waiting_for_return'
    },
    paymentConfirmDisabled() {
      return (
        this.kit.paymentReceived ||
        !['waiting_for_payment', 'waiting_for_return'].includes(this.kit.status)
      )
    },
    partialPaymentConfirmDisabled() {
      return [
        'waiting_for_payment',
        'waiting_for_return',
        'return_rejected',
        'close',
      ].includes(this.kit.status)
    },
  },
}
</script>
