import BigNumber from 'bignumber.js'

BigNumber.config({
  DECIMAL_PLACES: 2,
  ROUNDING_MODE: BigNumber.ROUND_HALF_CEIL,
  EXPONENTIAL_AT: [-10, 20],
  RANGE: [-500, 500],
  CRYPTO: true,
  MODULO_MODE: BigNumber.ROUND_FLOOR,
  POW_PRECISION: 80,
  FORMAT: {
    groupSize: 3,
    groupSeparator: '.',
    decimalSeparator: ','
  },
  ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$_'
})

const patterns = {
  integer: number =>
    new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 0
    }).format(number.toString()),
  numeric: (number, { minDecimals = 2 }) =>
    new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: minDecimals
    }).format(number.toString()),
  multiplier: number =>
    new Intl.NumberFormat('pt-BR', {
      maximumSignificantDigits: 3
    }).format(number.toString()) + 'x',
  currency: number =>
    Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(number.toString()),
  percent: number =>
    new Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 2
    }).format(number.toString()) + '%'
}

export function format(number, pattern, options) {
  return patterns[pattern] ? patterns[pattern](number, options || {}) : 0
}
