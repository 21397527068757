import BigNumber from 'bignumber.js'
window.BigNumber = BigNumber

BigNumber.config({
  DECIMAL_PLACES: 2,
  ROUNDING_MODE: BigNumber.ROUND_HALF_CEIL,
  EXPONENTIAL_AT: [-10, 20],
  RANGE: [-500, 500],
  CRYPTO: true,
  MODULO_MODE: BigNumber.ROUND_FLOOR,
  POW_PRECISION: 80,
  FORMAT: {
    groupSize: 3,
    groupSeparator: '.',
    decimalSeparator: ','
  },
  ALPHABET: '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$_'
})

import { format } from './number-formatter.js'

export default function configure() {
  const numericInputs = document.querySelectorAll('input.numeric')
  Array.from(numericInputs).forEach(element => {
    element.addEventListener('blur', ({ target }) => {
      target.value = formatNumericValue(target)
    })
  })
}

function formatNumericValue(element) {
  const pattern = element.getAttribute('format') || '0[,]0'
  let elementValue = new BigNumber(
    element.value.replace(/[^0-9,\.]/g, '').replace(',', '.')
  )

  if (elementValue.isNaN()) elementValue = BigNumber('0')

  return format(elementValue, pattern)
}
