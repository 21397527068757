import { request } from './request'

export function newProduct() {
  return request('/products/new.json')
}

export function getProduct(id) {
  return request(`/products/${id}.json`)
}

export function deleteProduct(id) {
  return request(`/products/${id}.json`, {
    method: 'delete',
  }).then(() => {
    location.reload()
  })
}

export function deleteImage(productId, imageId) {
  return request(`/products/${productId}/images/${imageId}.json`, {
    method: 'delete',
  })
}
