import BigNumber from 'bignumber.js'

export default function configure() {
  const costElements = document.querySelectorAll('[cost], [factor]')
  Array.from(costElements).forEach(element => {
    element.addEventListener('change', calculateProductPrice)
  })
}

function calculateProductPrice({ target }) {
  const form = target.closest('form')
  const costElement = form.querySelector('[cost]')
  const factorElement = form.querySelector('[factor]')
  const priceElement = form.querySelector('[price]')

  const cost = BigNumber(
    costElement.value.replace(/[^0-9,\.]/g, '').replace(',', '.')
  )

  const factor = BigNumber(
    factorElement.value.replace(/[^0-9,\.]/g, '').replace(',', '.')
  )

  priceElement.value = cost * factor
  priceElement.dispatchEvent(new Event('blur'))
}
