<template>
  <fragment>
    <input
      v-if="kitProduct.id"
      type="hidden"
      :name="`kit[kit_products_attributes][]id`"
      :value="`${kitProduct.id}`"
    />
    <input
      v-if="kitProduct._destroy"
      type="hidden"
      :name="`kit[kit_products_attributes][]_destroy`"
      v-model="kitProduct._destroy"
    />

    <div v-if="!kitProduct._destroy" class="columns" v-bind:class="{'has-error': !valid}">
      <div class="column col-xs-12 col-11">
        <div class="columns">
          <div class="column col-xs-12 col-md-7 col-4 form-group">
            <label class="form-label">Produto</label>
            <product-typeahead
              :onProductSelected="onProductSelected"
              :product-id="kitProduct.product.id"
              :name="`kit[kit_products_attributes][]product_id`"
            ></product-typeahead>
          </div>
          <div class="column col-xs-8 col-md-3 col-2 form-group">
            <label class="form-label">Valor</label>
            <currency-input
              size="3"
              class="float-left form-input numeric"
              :name="`kit[kit_products_attributes][]price`"
              v-model="kitProduct.price"
            />
          </div>
          <div class="column col-xs-7 col-md-2 col-1 form-group">
            <label class="form-label">Qtde.</label>
            <integer-input
              size="10"
              class="float-left form-input numeric"
              :name="`kit[kit_products_attributes][]quantity`"
              v-model="kitProduct.quantity"
            />
          </div>
          <div class="column col-xs-7 col-md-2 col-1 form-group">
            <label class="form-label">Devolv.</label>
            <integer-input
              size="10"
              class="float-left form-input numeric"
              :name="`kit[kit_products_attributes][]returned`"
              v-model="kitProduct.returned"
            />
          </div>
          <div class="column col-xs-7 col-md-2 col-1 form-group">
            <label class="form-label">Vend</label>
            <integer-input
              size="10"
              class="float-left form-input numeric"
              :name="`kit[kit_products_attributes][]sold`"
              v-model="kitProduct.sold"
            />
          </div>
          <div class="column col-xs-8 col-md-6 col-4 form-group">
            <div class="columns">
              <div class="column col-6 form-group">
                <label class="form-label">Total</label>
                <currency-input
                  size="3"
                  class="float-left form-input numeric"
                  disabled
                  :value="total"
                />
              </div>
              <div class="column col-6 form-group">
                <label class="form-label">Vendido</label>
                <currency-input
                  size="3"
                  class="float-left form-input numeric"
                  disabled
                  :value="soldTotal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="excludeAction === 'none'" class="column col-xs-12 col-1 form-group delete">
        <label class="form-label"></label>
        <button
          type="button"
          class="btn btn-danger flex-centered"
          v-on:click="markForDestruction()"
        >
          <i class="icon icon-delete"></i>
        </button>
      </div>
      <div v-if="excludeAction === 'confirm'" class="column col-xs-12 col-1 form-group delete">
        <button
          type="button"
          class="btn btn-success flex-centered"
          v-on:click="confirmDestruction()"
        >
          <i class="icon icon-check"></i>
        </button>
      </div>
    </div>
  </fragment>
</template>


<script>
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'

export default {
  data() {
    return {
      excludeAction: 'none',
      timeout: null,
    }
  },
  props: {
    kitProduct: {
      twoWay: true,
    },
  },
  computed: {
    total() {
      const price = BigNumber(
        this.kitProduct.price.replace('.', '').replace(',', '.')
      )
      const total = price
        .multipliedBy(new BigNumber(this.numeric(this.kitProduct.quantity)))
        .minus(
          price.multipliedBy(
            new BigNumber(this.numeric(this.kitProduct.returned))
          )
        )
      return format(total, 'currency')
    },
    soldTotal() {
      const price = BigNumber(
        this.kitProduct.price.replace('.', '').replace(',', '.')
      )
      const total = price.multipliedBy(
        new BigNumber(this.numeric(this.kitProduct.sold))
      )
      return format(total, 'currency')
    },
    valid() {
      return (
        (this.numeric(this.kitProduct.quantity) > 0 &&
          this.numeric(this.kitProduct.price) > 0 &&
          this.kitProduct.product_id > 0) ||
        this.kitProduct._destroy
      )
    },
  },
  methods: {
    markForDestruction() {
      this.excludeAction = 'confirm'

      this.timeout = setTimeout(function (scope) {
        this.excludeAction = 'none'
        this.timeout = null
      }, 2000)
    },
    confirmDestruction() {
      this.kitProduct._destroy = true
      clearTimeout(this.timeout)
    },
    onProductSelected(product) {
      this.kitProduct.product_id = product.id
      this.kitProduct.product = product
      this.kitProduct.price = product.price
      this.kitProduct.quantity = '0'
    },
    numeric(value) {
      if (['', null, undefined].includes(value)) return 0
      return value.match(/,[0-9]*$/i)
        ? value.replace('.', '').replace(',', '.')
        : value
    },
  },
}
</script>

<style lang="scss">
.has-error {
  background-color: rgba(255, 0, 0, 0.267);
}

#autosuggest {
  div:first-child {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.columns .column {
  margin-left: 0;
}

.kit-product {
  padding: 10px 0px;
  margin-bottom: 5px;
  box-shadow: 0px -3px 4px -6px rgba(0, 0, 0, 0.75);
}

.delete {
  display: flex;
  align-items: center;

  a {
    margin-top: 17px;
  }
}
</style>
