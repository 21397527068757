export default function configure() {
  const modalButtons = document.querySelectorAll('[modal-id]')
  Array.from(modalButtons).forEach(modalButton => {
    modalButton.addEventListener('click', ({ target }) => {
      const element = target.closest('[modal-id]')
      const modalId = element.getAttribute('modal-id')
      document.querySelector(`#${modalId}`).classList.add('active')
    })
  })

  const modalCloseButtons = document.querySelectorAll('[action="close"]')
  Array.from(modalCloseButtons).forEach(modalCloseButton => {
    modalCloseButton.addEventListener('click', ({ target }) => {
      const form = target.closest('.modal').querySelector('form')
      form && form.reset()
      target.closest('.modal').classList.remove('active')
    })
  })

  document.addEventListener('keyup', event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      const activeModal = document.querySelector('.modal.active')
      activeModal && activeModal.classList.remove('active')
    }
  })
}
