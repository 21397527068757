import VueAutosuggest from 'vue-autosuggest'
import OrderItems from './OrderItens.vue'
import DealerTypeahead from './DealerTypeahead.vue'
import ProductTypeahead from './ProductTypeahead.vue'
import IntegerInput from './IntegerInput.vue'
import CurrencyInput from './CurrencyInput.vue'
import KitTotals from './KitTotals.vue'
import KitBody from './KitBody.vue'
import KitFinalization from './KitFinalization.vue'

export default {
  VueAutosuggest,
  DealerTypeahead,
  ProductTypeahead,
  OrderItems,
  KitTotals,
  KitBody,
  IntegerInput,
  CurrencyInput,
  KitFinalization
}
