import tippy from 'tippy.js';

export default function configure() {
  tippy('[tippy-data-template]', {
    content(reference) {
      const id = reference.getAttribute('tippy-data-template');
      const template = document.getElementById(id);
      return `<div class="tippy-rollbar">${template.innerHTML}</div>`
    },
    allowHTML: true,
    trigger: 'click',
    interactive: true,
    placement: 'bottom'
  });
}
