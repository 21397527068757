<template>
  <fragment>
    <input
      v-if="orderProduct.id"
      type="hidden"
      :name="`order[order_products_attributes][]id`"
      :value="`${orderProduct.id}`"
    />
    <input
      v-if="orderProduct._destroy"
      type="hidden"
      :name="`order[order_products_attributes][]_destroy`"
      v-model="orderProduct._destroy"
    />

    <div v-if="!orderProduct._destroy" class="columns" v-bind:class="{'has-error': !valid}">
      <div class="column col-xs-12 col-5 form-group">
        <label class="form-label">Produto</label>
        <product-typeahead
          :filter="kind"
          :onProductSelected="onProductSelected"
          :product-id="orderProduct.product.id"
          :name="`order[order_products_attributes][]product_id`"
        ></product-typeahead>
      </div>
      <div class="column col-xs-12 col-2 form-group">
        <label class="form-label">Quantidade</label>
        <integer-input
          size="10"
          class="float-left form-input numeric"
          :name="`order[order_products_attributes][]quantity`"
          v-model="orderProduct.quantity"
        />
      </div>
      <div class="column col-xs-12 col-2 form-group">
        <label class="form-label">Valor</label>
        <currency-input
          size="3"
          class="float-left form-input numeric"
          :name="`order[order_products_attributes][]price`"
          v-model="orderProduct.price"
        />
      </div>
      <div class="column col-xs-12 col-2 form-group">
        <label class="form-label">Total</label>
        <currency-input size="3" class="float-left form-input numeric" disabled :value="total" />
      </div>
      <div v-if="excludeAction === 'none'" class="column col-xs-12 col-1 form-group delete">
        <label class="form-label"></label>
        <a class="btn btn-danger flex-centered" v-on:click="markForDestruction()">
          <i class="icon icon-delete"></i>
        </a>
      </div>
      <div v-if="excludeAction === 'confirm'" class="column col-xs-12 col-1 form-group delete">
        <a class="btn btn-success flex-centered" v-on:click="destroy()">
          <i class="icon icon-check"></i>
        </a>
      </div>
    </div>
  </fragment>
</template>


<script>
import { format } from '../number-formatter.js'
import BigNumber from 'bignumber.js'

export default {
  data() {
    return {
      excludeAction: 'none',
      timeout: null,
    }
  },
  props: {
    kind: { type: String, default: 'direct_sale' },
    orderProduct: {
      twoWay: true,
    },
  },
  computed: {
    total() {
      const price = BigNumber(this.numeric(this.orderProduct.price))
      const total = price.multipliedBy(
        new BigNumber(this.numeric(this.orderProduct.quantity))
      )

      return format(total, 'currency')
    },
    valid() {
      return (
        (this.numeric(this.orderProduct.quantity) > 0 &&
          this.numeric(this.orderProduct.price) > 0 &&
          this.orderProduct.product_id > 0) ||
        this.orderProduct._destroy
      )
    },
  },
  methods: {
    markForDestruction() {
      this.excludeAction = 'confirm'

      this.timeout = setTimeout(function (scope) {
        this.excludeAction = 'none'
        this.timeout = null
      }, 2000)
    },
    destroy() {
      clearTimeout(this.timeout)
      this.orderProduct._destroy = true
    },
    update(key, value) {
      this.$emit('input', { ...this.order, [key]: value })
    },
    onProductSelected(product) {
      this.orderProduct.product_id = product.id
      this.orderProduct.product = product
      this.orderProduct.price = product.price
      this.orderProduct.quantity = '0'
    },
    numeric(value) {
      if (['', null, undefined].includes(value)) return 0
      return value.match(/,[0-9]*$/i)
        ? value.replace('.', '').replace(',', '.')
        : value
    },
  },
}
</script>

<style lang="scss">
.has-error {
  background-color: rgba(255, 0, 0, 0.267);
}

#autosuggest {
  div:first-child {
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.columns .column {
  margin-left: 0;
}

.order-product {
  padding: 10px 0px;
  margin-bottom: 5px;
  box-shadow: 0px -3px 4px -6px rgba(0, 0, 0, 0.75);
}

.delete {
  display: flex;
  align-items: center;

  a {
    margin-top: 17px;
  }
}
</style>
