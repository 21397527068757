<template>
  <div>
    <vue-autosuggest
      v-model="query"
      :suggestions="suggestions"
      @input="onInputChange"
      @selected="onSelected"
      @focus="onFocus"
      :input-props="{
        name: 'product',
        placeholder: 'Escolha um vendedor',
      }"
    >
      <template slot="after-input">
        <label class="suggestion-icon">
          <img :src="this.images.glassClear" />
        </label>
        <div class="break"></div>
      </template>
      <template slot-scope="{ suggestion }">
        <span class="suggestion-item">{{ `${suggestion.item.id} - ${suggestion.item.name}` }}</span>
      </template>
    </vue-autosuggest>
    <input type="hidden" :name="this.name" v-bind:value="product.id" />
  </div>
</template>

<script>
import * as productService from '../product/service'
import glassClear from '../icons/glass-clear.svg'

export default {
  data() {
    return {
      images: { glassClear: glassClear },
      query: '',
      selected: {},
      suggestions: [{ data: [] }],
      product: {},
    }
  },
  props: ['productId', 'name', 'onProductSelected', 'filter'],
  mounted: async function () {
    this.findProduct(this.productId)
  },
  methods: {
    async findProduct(id) {
      if (!id) return

      return productService.find(id).then(async (response) => {
        if (response.ok) {
          this.product = await response.json()
          this.suggestions[0].data = [this.product]
          this.query = this.product.name
          this.product = this.product
        }
      })
    },
    onSelected({ item }) {
      this.product = item
      this.query = this.product.name
      this.onProductSelected && this.onProductSelected(item)
    },
    onInputChange(text) {
      this.product = {}
      return productService
        .search(text || '', `direct_sale=${this.filter === 'direct_sale'}`)
        .then(async (response) => {
          if (response.ok) {
            this.suggestions[0].data = await response.json()
          }
        })
    },
    onFocus({ target: { value } }) {
      return this.onInputChange(value)
    },
  },
}
</script>

<style lang="scss">
ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}

.suggestion-icon {
  display: inline-block;
}

#autosuggest {
  display: flex;
  flex-flow: row wrap;

  input {
    width: 260px;
    padding: 0.5rem;
    margin: 0;
  }

  label {
    border-left: 1px solid black;
    height: fit-content;
    padding: 7px 7px 3px 7px;
    margin-left: -36px;
    margin-top: 3px;

    img {
      width: 20px;
    }
  }
}

.break {
  display: block;
  width: 100%;
}

#autosuggest__input {
  outline: none;
  display: block;
  font-family: monospace;
  font-size: 20px;
  border: 1px solid #616161;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  &.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.autosuggest__results-container {
  position: absolute;
  margin-top: 40px;
  z-index: 1;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow: scroll;
  max-height: 200px;

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  .autosuggest__results-item {
    cursor: pointer;
    padding: 15px;
  }

  .autosuggest__results-title {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
  }

  .autosuggest__results-item:active,
  .autosuggest__results-item:hover,
  .autosuggest__results-item:focus,
  .autosuggest__results-item.autosuggest__results_item-highlighted {
    background-color: #ddd;
  }
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}
</style>
