export function numeric(value) {
  return parseFloat(
    value.match(/,[0-9]*$/i)
      ? value
          .replace('.', '')
          .replace(/[^0-9,\.]/g, '')
          .replace(',', '.')
      : value
  )
}
