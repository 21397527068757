<template>
  <fragment>
    <div class="columns">
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Pago</label>
        <currency-input v-model="kit.paid_total"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Devolvido</label>
        <currency-input v-model="kit.returned"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Vendido</label>
        <currency-input v-model="kit.sold"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Total</label>
        <currency-input v-model="kit.total" :disabled="true"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Débito</label>
        <currency-input :value="debit" :disabled="true"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>Comissão</label>
        <currency-input :value="commission" :disabled="true"></currency-input>
      </div>
      <div class="form-group column col-xs-12 col-md-3 col-2">
        <label>A pagar</label>
        <currency-input :value="toPay" :disabled="true"></currency-input>
      </div>
    </div>
  </fragment>
</template>

<script>
import { variableCommission, fixedCommission } from '../commission'
import { numeric } from '../numeric'

export default {
  data() {
    return {}
  },
  props: {
    kit: { type: Object, default: {}, twoWay: true },
  },
  computed: {
    debit() {
      return String(
        numeric(this.kit.total) -
          numeric(this.kit.paid_total) -
          numeric(this.kit.returned) -
          numeric(this.commission)
      )
    },
    commission() {
      const commision = this.kit.dealer.variable
        ? variableCommission(numeric(this.kit.total), numeric(this.kit.sold))
        : fixedCommission(numeric(this.kit.sold), numeric(this.kit.dealer.fee))

      return String(commision)
    },
    toPay() {
      return String(
        numeric(this.kit.sold) -
          numeric(this.commission) -
          numeric(this.kit.paid_total)
      )
    },
  },
}
</script>
