import Autocomplete from '@trevoreyre/autocomplete-js'
import * as product from './product/service'
import * as kindService from './kind/service'
import * as colorService from './color/service'
import * as categoryService from './category/service'

export function configureFor(selector) {
  colorTypeahead(selector)
  categoryTypeahead(selector)
  kindTypeahead(selector)
  productTypeahead(selector)
}

function colorTypeahead(selector) {
  applyTypeahead(
    `${selector} [color-typeahead]`,
    'name',
    colorService.search,
    feedback => {
      typeaheadCallback(feedback, selector, 'color_id')
    }
  )
}

function typeaheadCallback(feedback, selector, name) {
  if (feedback)
    document.querySelector(`${selector} [name="${name}"]`).value = feedback.id
}

function kindTypeahead(selector) {
  applyTypeahead(
    `${selector} [kind-typeahead]`,
    'name',
    kindService.search,
    feedback => {
      typeaheadCallback(feedback, selector, 'kind_id')
    }
  )
}

function categoryTypeahead(selector) {
  applyTypeahead(
    `${selector} [category-typeahead]`,
    'name',
    categoryService.search,
    feedback => {
      typeaheadCallback(feedback, selector, 'category_id')
    }
  )
}

function productTypeahead(selector) {
  applyTypeahead(
    `${selector} [product-typeahead]`,
    'name',
    product.search,
    feedback => {
      typeaheadCallback(feedback, selector, 'product_id')
    }
  )
}

export function applyTypeahead(
  selector,
  propertyName,
  queryFunction,
  onSelect
) {
  Array.from(document.querySelectorAll(selector)).forEach(element => {
    new Autocomplete(element, {
      debounceTime: 300,
      search: input => {
        element.addEventListener('keydown', disableSubmit)
        return queryFunction(input).then(async response => {
          const data = await response.json()
          return data
        })
      },
      getResultValue: result => result[propertyName],
      onSubmit: (e, a) => {
        onSelect(e)
        setTimeout(() => {
          element.removeEventListener('keydown', disableSubmit)
        }, 1)
      }
    })
  })
}

function disableSubmit(event) {
  if (event.code == 'Enter') {
    event.preventDefault()
    return false
  }

  return true
}
